.site-wrapper {
  display: flex;
  flex-direction: column;
}

.site-section {
  width: 95%;
  margin: 0 2.5%;

  @include largeDesktopMin {
    width: 1200px;
    margin: 0 calc(50vw - 600px);
  }
}

.banner {
  width: 100%;
  height: auto;

  @include tabletMax {
    margin-top: 50px;
  }
}

.site-title {
  font-family: PoppinsLight;
  font-size: 52px;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid $colorBlue5;
  color: $colorBlue5;
  margin-top: 7px;

  @include mobileMax {
    font-size: 28px;
  }
}

.sides-wrapper {
  position: relative;
  display: flex;

  @include tabletMax {
    flex-direction: column;
  }
}

.left-side {
  width: 50%;

  @include tabletMax {
    width: 100%;
  }
}

.right-side-wrapper {
  width: 50%;
  justify-content: center;
  flex-direction: column;

  @include tabletMax {
    width: 100%;
  }
}

.right-side {
  justify-content: center;
  flex-direction: column;
  display: flex;
  position: sticky;
  top: 60px;

  & > * {
    margin: 0 auto;
  }
}

.quote {
  color: $colorBlue3;
  font-size: 24px;
  text-align: center;
  margin: 20px 0;
  font-style: italic;
  width: 95%;
  padding: 0 2.5%;

  &.no-c {
    font-style: initial;
  }
}

.quote-big-wrapper {
  position: relative;
  max-width: 1200px;
  padding: 0 calc(50vw - 600px);
  background-color: $colorBlack;

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center 25%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    opacity: 0.2;

    @include mobileMax {
      background-size: 100% 100%;
    }
  }
}

.quote-big {
  font-size: 24px;
  text-align: center;
  padding: 20px 20px 15px;
  font-family: PoppinsLight;
  color: $colorWhite;

  & b {
    text-decoration: underline;
    font-size: 26px;
  }

  @include mobileMax {
    font-size: 18px;

    & b {
      text-decoration: underline;
      font-size: 20px;
    }
  }
}

.paragraph {
  margin: 5px;
  font-size: 15px;
  font-family: PoppinsLight;
  text-align: justify;

  & b {
    font-size: 16px;
  }
}
