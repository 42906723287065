.menu-wrapper {
  position: sticky;
  width: 100%;
  background-color: $colorBlack;
  height: 50px;
  top: 0;
  display: flex;
  box-shadow: 0 0 5px $colorBlack;
  z-index: 2;

  @include largeDesktopMin {
    width: 1200px;
    padding: 0 calc(50vw - 600px);
  }

  @include tabletMax {
    display: none;
  }

  &.mobile {
    @include desktopMin {
      display: none;
    }
    @include tabletMax {
      display: flex;
    }

    position: fixed;
    top: 0;
    height: 50px;
    background-color: $colorWhiteDirty;
    transition: height 0.25s;

    &.shown {
      height: 100vh;
    }
  }
}

.menu-logo {
  height: 80px;
  margin: 10px;
  width: auto;
  z-index: 4;

  .mobile & {
    position: absolute;
  }
}

.menu-switch {
  position: absolute;
  top: 13px;
  right: 13px;
  z-index: 5;
  cursor: pointer;
}

.menu-line {
  height: 0;
  width: 25px;
  border: 1px solid $colorBlack;
  margin: 5px;
  border-radius: 5px;

  .shown & {
    &.first {
      transform: rotate(-45deg);
      margin-top: 14px;
    }

    &.second {
      display: none;
    }

    &.third {
      transform: rotate(45deg);
      margin-top: -7px;
    }
  }
}

.menu-blur {
  position: fixed;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  height: 50px;
  z-index: 1;
  background-image: url("https://assets.dmksiegowosc.eu/thumbs/pictures/slider-default-1500.jpg");
  background-color: $colorWhiteDirty;
  background-size: 100% auto;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top center;
  filter: blur(5px);

  @include largeDesktopMin {
    background-size: 100% auto;
  }

  @include tabletMax {
    border-bottom: 1px solid $colorBlack;
    z-index: 3;
    box-shadow: 1px 0 5px 1px $colorBlack;
  }
}

.menu-lister {
  margin: 0;
  list-style: none;
  height: 100%;
  align-items: flex-end;
  display: flex;
  z-index: 4;

  .mobile & {
    align-items: initial;
    flex-direction: column;
    width: 100%;
    margin-top: 60px;
  }
}

.menu-item {
  float: left;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 5px;
  color: $colorBlue2;

  .active & {
    border-bottom: 2px solid $colorWhiteDirty;
  }

  .mobile & {
    width: 95%;
    margin: 5px 2.5%;
  }
}

.menu-item-icon {
  display: flex;

  & svg {
    font-size: 30px;
    margin: auto 3px 0 0;
    color: $colorWhite;
    filter: drop-shadow(0 0 1px #000);
    transition: color 0.25s;

    .menu-item:hover & {
      color: $colorLightBlue;
    }
  }

}

.menu-item-text {
  //A
  transition: color 0.25s;
  text-shadow: rgb(0, 0, 0) 1px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px;
  color: $colorWhite;
  font-family: Arial;
  font-size: 18px;

  .menu-item:hover & {
    color: $colorLightBlue;
  }
}

.menu-item-wrapper {
  position: relative;
  justify-content: center;
}

.sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 0 0 5px 5px;
  background-color: #000;
  box-shadow: 0 1px 3px 1px rgba($colorBlack, 0.5);

  .menu-item-wrapper:hover & {
    display: block;
  }

  @include mobileMax {
    width: 100%;
    display: block;
  }
}

.sub-menu-item {
  transition: 0.25s ease-in-out;
  display: flex;
  align-items: center;
  padding: 5px;
  color: $colorWhiteDirty;
  text-shadow: rgb(0, 0, 0) 1px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px;

  &:hover {
    background-color: rgba($colorWhiteDirty, 0.5);
    text-shadow: none;
  }
}

.sub-menu-item-text {
  margin: auto 0;
  text-align: center;
  width: 100%;
  text-decoration: none;
  font-size: 18px;
  font-family: Arial;
}
