@font-face {
  font-family: "PoppinsLight";
  src: url("../fonts/poppins-light.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins-regular.ttf");
}

@font-face {
  font-family: "Pompiere";
  src: url("../fonts/pompiere.otf");
}
