.contact-form {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 0 $colorBlack;
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
}

.fast-contact-form {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 3px 0 $colorBlack;
  margin: 0 10px 10px 10px;
  border-radius: 0 0 5px 5px;
  padding: 10px;
  background-color: rgba($colorDarkBlue, 0.1);
  width: 350px;
  overflow: hidden;

  @include mobileMax {
    width: 300px;
    margin: 10px auto;
    border-radius: 5px;
  }
}

.form-input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $colorSilver;
  border-radius: 5px;
  padding: 3px 10px 1px;
  transition: border-color 0.25s;

  &.error {
    border-color: $colorRed;
  }

  &:focus {
    border-color: $colorBlack;
  }

  .contact-form & {
    width: 300px;

    @include mobileMax {
      width: 100%;
    }
  }
}

.form-input-wide {
  @extend .form-input;
  resize: vertical;
  min-height: 100px;


  .contact-form & {
    width: 100%;
  }
}

.form-submit {
  margin: 5px 5px 0 auto;
  border-radius: 3px;
  background-color: royalblue;
  padding: 3px 8px 2px;
  color: $colorWhite;
  cursor: pointer;

  &:hover {
    background-color: $colorGreen;
  }
}

.form-contact-name {
  margin: 3px 5px 1px;
}

.contact-info-wrapper {
  display: flex;
  justify-content: space-evenly;

  @include tabletMax {
    flex-direction: column;
    justify-content: center;
  }
}

.contact-info {
  margin: 0 auto;

  & td:nth-of-type(odd) {
    display: flex;
    justify-content: center;
    padding-top: 5px;

    & svg {
      margin: auto 5px;
    }
  }

  & td:nth-of-type(even) {
    padding-left: 10px;
  }
}

.location-address {
  margin: 0 auto;
}

.open-hours {
  margin: 0 auto;

  & td:nth-of-type(even) {
    padding-left: 10px;
  }
}