.pricing-value {
  color: $colorGreen;
}

.pricing-wrapper {
  display: flex;
  justify-content: space-between;

  @include mobileMax {
    flex-direction: column;
    justify-content: center;
  }
}

.pricing-text {
  width: 65%;

  @include mobileMax {
    width: 100%;
  }
}
