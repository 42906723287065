@keyframes goldenOutline {
  0% {
    text-shadow: rgba(255, 183, 43, 0.5) 1px 0px 0px,
      rgba(255, 183, 43, 0.5) 0.540302px 0.841471px 0px,
      rgba(255, 183, 43, 0.5) -0.416147px 0.909297px 0px,
      rgba(255, 183, 43, 0.5) -0.989992px 0.14112px 0px,
      rgba(255, 183, 43, 0.5) -0.653644px -0.756802px 0px,
      rgba(255, 183, 43, 0.5) 0.283662px -0.958924px 0px,
      rgba(255, 183, 43, 0.5) 0.96017px -0.279415px 0px;
  }
  50% {
    text-shadow: none;
  }
  100% {
    text-shadow: rgba(255, 183, 43, 0.5) 1px 0px 0px,
      rgba(255, 183, 43, 0.5) 0.540302px 0.841471px 0px,
      rgba(255, 183, 43, 0.5) -0.416147px 0.909297px 0px,
      rgba(255, 183, 43, 0.5) -0.989992px 0.14112px 0px,
      rgba(255, 183, 43, 0.5) -0.653644px -0.756802px 0px,
      rgba(255, 183, 43, 0.5) 0.283662px -0.958924px 0px,
      rgba(255, 183, 43, 0.5) 0.96017px -0.279415px 0px;
  }
}
