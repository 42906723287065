.slide-wrapper {
  width: 600px;
  height: auto;
  padding: 0 300px;

  @include desktopMax {
    padding: 0 calc(50vw - 330px);
  }

  @include tabletMax {
    width: 92vw;
    padding: 0 2vw;
  }
}

.slide-inner-wrapper {
  width: 99%;
  margin: 10px 0;
  box-shadow: 0 0 5px 1px $colorBlack;
}

.slide-image {
  width: 100%;
  height: auto;
}

.slider-wrapper {
  position: relative;
}

.arrow {
  position: absolute;
  height: 100%;
  width: 50px;
  display: flex;
  font-size: 40px;
  top: 0;
  align-items: center;
  cursor: pointer;
  color: rgba($colorBlack, 0.2);
  //A
  transition: color 0.25s;

  &:hover {
    color: $colorBlue5;
  }

  &.left {
    left: -15px;
    transform: scale(-1, 1);
  }

  &.right {
    right: -15px;
  }

  @include tabletMax {
    color: $colorBlue5;
    top: -68px;
    align-items: flex-start;
  }

  @include mobileMax {
    top: -45px;
    font-size: 30px;
  }
}

.tiles {
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

.tile {
  position: relative;
  width: 220px;
  height: 220px;
  background-size: cover;
  background-position: center center;
  transition: box-shadow 0.25s ease-in-out;
  box-shadow: 0 0 5px 1px rgba($colorBlack, 0.5);
  margin: 30px auto;

  &:hover {
    box-shadow: 0 0 5px 1px $colorBlack;
  }
}

.tile-wrapper {
  width: 100%;
  height: auto;

  @include tabletMax {
    margin: 20px;
  }
}

.tile-image {
  width: 100%;
  height: auto;
}

.tile-title {
  position: absolute;
  bottom: -12px;
  right: -12px;
  background-color: $colorWhiteDirty;
  color: $colorBlack;
  box-shadow: 0 0 5px 1px $colorBlack;
  border-radius: 5px;
  overflow: hidden;
  padding: 3px 8px 1px;
}


.site-row {
  display: flex;

  @include mobileMax {
    flex-direction: column;
  }
}

.site-left {
  width: 70%;
  display: flex;
  justify-content: space-evenly;

  @include mobileMax {
    width: 100%;
  }
}

.site-right {
  width: 30%;

  @include mobileMax {
    width: 100%;
  }
}