@import "./_animations.scss";
@import "./_colors.scss";
@import "./_fonts.scss";
@import "./_media.scss";

* {
  font-family: Poppins;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

body {
  margin: 0;
  overflow-x: hidden;
  min-height: 110vh;
}

@import "./contact.scss";
@import "./menu.scss";
@import "./popups.scss";
@import "./site.scss";
@import "./aboutUs.scss";
@import "./home.scss";
@import "./pricing.scss";
